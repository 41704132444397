import {
  Modal,
  SpaceBetween,
  Button,
  Checkbox
} from '@cloudscape-design/components';
import { useState, useRef, useContext, useEffect } from 'react';
import { UserContext } from '../../../context/authContext';
import { DocumentContext } from '../../../context/documentContext';
import Cookies from 'js-cookie';

interface ModalProps {}
export const TandCModal = () => {
  const {
    user,
    updateTNC,
    latestTNCAcknowledged,
    userChecksComplete,
    logout,
    showNoticeModal,
    setShowNoticeModal
  } = useContext(UserContext);
  const { setMainAlert } = useContext(DocumentContext);
  const [countryAccepted, setCountryAccepted] = useState<boolean>(false);
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);
  const [showRejectDialog, setShowRejectDialog] = useState<boolean>(false);
  const [showAcceptPrivacy, setShowAcceptPrivacy] = useState<boolean>(false);
  const divRef2 = useRef<HTMLDivElement | null>(null);

  const handleScrollPrivacy = () => {
    if (!divRef2.current) return;

    const { scrollTop, scrollHeight, clientHeight } = divRef2.current;

    if (scrollTop + clientHeight >= scrollHeight) {
      setShowAcceptPrivacy(true);
    }
  };
  useEffect(() => {
    if (userChecksComplete) {
      if (!latestTNCAcknowledged && userChecksComplete) {
        setShowNoticeModal(true);
      } else {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userChecksComplete]);

  const handleAcceptNotice = async () => {
    try {
      const result = await updateTNC();
      console.log('result', result);
      setMainAlert({
        alertType: 'success',
        showAlert: true,
        message: 'Successfully updated'
      });
      setShowNoticeModal(false);
      Cookies.set('userM', JSON.stringify(result.data), { expires: 7 });
    } catch (e) {
      const error = e as Error;
      setMainAlert({
        alertType: 'error',
        showAlert: true,
        message: error.message
      });
      setShowNoticeModal(false);
    }
  };
  return (
    <Modal
      visible={showNoticeModal}
      size="max"
      footer={
        <SpaceBetween size={'xs'} direction="horizontal" alignItems="end">
          <Button
            onClick={() => {
              setShowRejectDialog(true);
            }}
          >
            Reject
          </Button>
          <Button
            onClick={() => handleAcceptNotice()}
            disabled={!privacyAccepted || !countryAccepted}
          >
            Accept
          </Button>
        </SpaceBetween>
      }
    >
      {/* <SpaceBetween size={'s'} direction="vertical"> */}
      <>
        <h2>{`Welcome to the ${process.env.REACT_APP_TITLE}`}</h2>

        {/* <h3>Privacy Policy</h3> */}
        <div
          style={{ maxHeight: '400px', overflowY: 'scroll' }}
          ref={divRef2}
          onScroll={handleScrollPrivacy}
        >
          <pre
            style={{ whiteSpace: 'pre', margin: '0', wordWrap: 'break-word' }}
          >
            {`
TERMS AND CONDITIONS
THESE TERMS AND CONIDITIONS ("Agreement") and the Privacy Policy (“Privacy Policy”) constitute a binding agreement between you ("Customer" or "you") and COMPLIANCE CHANNEL PARTNERS, LLC, 
a Florida limited liability company ("Company" or “us/we/our”). This Agreement governs your use of the Company website located at https://compliancechannelpartners.com (the “Site”), 
the MetaTrace platform, Company’s compliance management platform (“Software”), and the Company Offerings described below (the Site, Software, Offerings, Project Types and subscription, 
collectively referred to as the “Services”). Please read this Agreement carefully.
BY PAYING SUBCRIPTION OR OTHER FEES, CLICKING THE "AGREE" BUTTON, OR USING THE SERVICES, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; (B) REPRESENT THAT YOU ARE 
OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, 
DO NOT USE THE SERVICES.
To register with and use the Services, you must be 18 years or older and/or be of legal age in your jurisdiction of residence, or be between the age of 13 and 17 and obtain the 
express permission of your parent or legal guardian. If you are under the age of 13, you are not permitted to use any of the Services or submit any personally identifiable information 
to the Company.
1.	Privacy Policy
By agreeing to this Agreement, you agree to the terms of our Privacy Policy, which is incorporated herein by reference. Before using the Services, please read through this Agreement 
and Privacy Policy carefully. All personal information provided to us as a result of your use of the Services will be handled in accordance with our Privacy Policy.
2.	Services 
2.1	Company Platform. The Company platform enables you to utilize document intelligence and knowledge management system, automate document analysis and content classification, create 
    traceable connections between documents, knowledge base creation and search capabilities, document relationship tracking, and original document integrity maintained without modification 
    (“Offerings”) through two different project types, Workspace Projects and Information Request Projects (“Project Types”). Customers may participate in the Services through subscription 
    plans. We reserve the right to modify, terminate or otherwise amend our offered options and plans at any time in our discretion. From time to time, we may permit non-subscribers to access 
    certain Services, content or features for a cost or at no cost. Company makes no commitment on the quantity, availability, type or frequency at which such Services, content and features 
    will be available to non-subscribers and may modify, discontinue, remove or suspend access at any time and for any reason in our sole discretion.
2.2	Supported File Types and System Limitations. All Project Types support PDF files as the standard format, whereas Information Request Projects also allow for CSV and DOCX file submissions. 
    There are no maximum file size restrictions for uploads onto the Services, though uploads are subject to the limitations of the subscription quota. System capabilities limitations include, 
    but are not limited to, single-sentence selection for analysis and basic CSV reporting, and no real-time collaboration features, external system integrations, direct document editing, or 
    automated decision-making and legal interpretation.
2.3	Account Registration. You may be required to create an account to access all or part of the Services. By registering an account, you agree to: (a) provide accurate, complete, and current 
    information as prompted by the registration forms; (b) designate an authorized representative to establish a principal administrator for the system; (c) provide a valid email address and 
    maintain it for account communications; (d) accept and adhere to the Terms of Use and Privacy Policy; (e) ensure that your subscription payment is current and up-to-date; (f) promptly 
    update your account information, including credit card numbers and expiration dates, to ensure accuracy; (g) maintain the security and confidentiality of your account information, including 
    your password; (h) notify the Company immediately if you become aware of any unauthorized access to or use of your account or password, or any other breach of security; 
    (i) take full responsibility for all activity under your account, including unauthorized access; (j) refrain from sharing your account credentials with third parties or using another 
    account holder's credentials; and (k) use the Services only as permitted under this Agreement when logging in.
2.4	Access Control Structure. The Services implement a robust access control structure to ensure secure and efficient management of user permissions. Permissions can be configured at the project, 
    folder, and document levels, allowing control over sharing and access. Role-based access management is employed, distinguishing between administrator-type users, who have advanced permissions 
    and control over system settings, and non-administrator-type users with restricted capabilities. Authentication is managed through AWS, leveraging secure protocols, and is paired with complex 
    password requirements to enhance account security. All data is stored in encrypted formats and isolated logically to prevent unauthorized access. Additionally, access activities are logged for
     auditing purposes, ensuring transparency and accountability in system usage.
2.5	Ownership of Content. Unless otherwise indicated, the content of the Services, including, but not limited to, all information, the design, text, images, graphics, audio clips, video clips,
     metadata, data, selection and arrangement of elements, organization, and the design, compilation, and other matters related to the Services (collectively, "Content") and the source and 
     object code, format, algorithm, and structure of the Software are protected under applicable intellectual property and other laws. All Content and intellectual property rights herein are 
     the property of the Company or used with the permission of the rights owner and are protected pursuant to applicable intellectual property laws. The posting of any Content on the Services 
     does not constitute a waiver of any right in such Content. The reproduction, duplication, distribution (including by way of email or other electronic means), publication (including posting 
     on another website) modification, copying or transmission of Content from the Software or the Site in any form or by any means is strictly prohibited without the express prior written consent 
     of Company. If approved, the Content may only be used for personal, non-commercial purposes. Notwithstanding the foregoing, any content uploaded to the Services by the Customer, along with any 
     derived content resulting from the Customer’s use of the Services, shall remain the sole property of the Customer. The Company claims no ownership rights over such Customer content, and nothing 
     in this Agreement shall be interpreted as transferring ownership of the Customer’s uploaded or derived content to the Company. You represent and warrant that you have all rights, licenses, 
     and permissions needed to upload content to our Services. 
2.6	Company Mark. The Company logo and all page headers, custom graphics, button icons, trademarks, service marks and logos appearing in the Software or the Site, unless otherwise noted, are service 
    marks, trademarks (whether registered or not) and/or trade dress of Company (the "Marks"). You are not authorized to display or use the Marks in any manner without the express prior written 
    consent of the Company. The use or misuse of the Marks or other trademarks, names, logos, service marks and/or trade dress or any other materials contained herein, except as permitted herein, 
    is expressly prohibited. All other product and company names and trademarks mentioned, displayed, cited or otherwise indicated on the Site or in the Software are the property of their respective 
    owners.
2.7	License Grant. Subject to your compliance with the requirements and restrictions of this Agreement, and STRICTLY FOR YOUR PERSONAL, NON-COMMERCIAL USE, we grant you a limited, revocable, 
    non-exclusive, non-sublicensable, non-transferrable, non-assignable, license to (a) access and use the Services and to view the Content, and (b) to download and install the Software on a device 
    you own or are authorized to use. You acknowledge and agree that the Software is provided under license, and not sold, to you. You do not acquire any ownership interest in the Software 
    under this Agreement, or any other rights thereto other than to use the Software in accordance with the license granted, and subject to all terms, conditions, and restrictions under this Agreement.
3.	Reseller Framework
    If the Customer obtains access to or receives the Services through an approved reseller of the Company (“Reseller”), the terms of this Agreement shall apply to the Customer's use of 
    the Services. The following additional terms govern the Reseller’s role and responsibilities:
    -	Billing Management: The Reseller is responsible for managing all billing-related activities, including invoicing and payment collection from the Customer.

    -	Paywall Control: The Company retains control over the paywall mechanisms that govern access to the Services, ensuring compliance with subscription terms and access restrictions.

    -	Pricing Tools: The Reseller has access to the Company's pricing tools to configure and manage subscription pricing as authorized by the Company.

    -	Subscription Changes: The Reseller is responsible for handling all subscription modifications, including upgrades, downgrades, or cancellations, in coordination with the Customer and in 
      accordance with this Agreement.
The Customer acknowledges that the Reseller acts as an intermediary for certain administrative functions but that the Company remains the provider of the Services and enforces the terms of this Agreement.
4.	Prices and Payment
4.1	Payment Terms. Customer shall pay fees for the Services on a per-user/seat basis, which includes a base processing volume allocation and a base storage allocation, as outlined in this Agreement and 
    the Customer’s subscription plan (“Fees”). Except as otherwise specified herein, (a) Fees are based on the Services licensed and not actual usage, and (b) all payment obligations under this Agreement 
    are non-refundable. All amounts payable by Customer are exclusive of any taxes, fees, duties, shipping, or other charges, however designated, now or hereafter levied. Customer will be responsible for 
    all taxes, fees, duties, shipping or other such charges under this Agreement. Customer agrees to be responsible for payment for all activity by third parties who access or use the Services through 
    Customer’s account regardless of whether such activity was authorized by Customer or not. Customer is responsible for all incidental charges related to their use of the Services such as charges for 
    Internet access, third party software licenses, text messaging, or other data transmission.
    Customer may purchase additional capacity for the Services at any time, including, but not limited to, the following: (a) extra user seats may be added to the subscription; 
    (b) additional processing volume; and 
    (c) additional storage capacity. Fees for these add-ons will be billed at the rates specified by the Company. Add-ons and upgrades are subject to the terms of this Agreement and will align with 
    the duration of the Customer’s existing subscription unless otherwise stated.
4.2	Payment method. For paid subscriptions for use of the Services, Customer hereby authorizes Company to automatically charge Customer’s payment method on each agreed-upon periodic renewal until either 
    Customer or Company terminates this Agreement or cancels the Services. Once a payment has been made, it is non-refundable. Customer is responsible for providing Company with its most current billing 
    information. Fees will appear on an invoice, which will be sent to the Customer via the email provided when charging occurs. Customers have approximately two weeks to bring up and settle any issues 
    with the billing of Fees.
4.3	Price Changes. We reserve the right to make changes to the price, frequency, or date of charge. If the cost of your Fee increases, you will be provided with notice prior to the billing period in which 
    the change will become effective and you must opt into the price increase in order to continue to have access to the Services following the end of your current billing period.
4.4	Promotions. Promotions for the Services may be authorized by Company from time to time, in its sole discretion, to customers. Such promotions shall be reflected on the purchase order on the Website, 
    Software, and in invoices.
4.5	Cancellation. You may cancel your subscription at any time. If you cancel your subscription prior to the end of the then current subscription period, you will be entitled to continue to access 
    to the Service through to the end of your current subscription period. At the end of your subscription period, you will immediately lose all access to the Services. 
    Please see our “No Refunds” policy below in Section 3.6.
4.6	No Refunds. While you may cancel your subscription at any time, we do not offer refunds or credits, except in our sole discretion, or as required by applicable laws. If we do issue a refund or credit, 
    we are under no obligation to offer similar refunds or credits in the future.
4.7	Subscription Management and Payment Processing. All subscription management and payment processing for the Services are handled securely through Stripe, a third-party payment processor. 
    By subscribing to the Services, you agree to abide by Stripe’s terms of service and privacy policy, which govern the payment processing services. The Company does not store or have access to your 
    complete payment information; all sensitive payment data is securely managed by Stripe. It is your responsibility to ensure that your payment information remains accurate and up-to-date within the 
    Stripe system to avoid any interruptions in service.
4.8	Payment Resolution. If the Customer obtains the Services through a Reseller and encounters issues with payment, the Customer must primarily address and resolve such issues directly with the Reseller. 
    This includes disputes, payment failures, or questions regarding billing statements. The Company is not responsible for resolving payment-related issues managed by the Reseller but may, at its 
    discretion, assist the Reseller in addressing such issues. The Customer remains responsible for ensuring timely payment of all Fees as required under this Agreement, whether through the 
    Reseller or directly to the Company, as applicable.
5.	Term and Termination
    The term of Agreement commences when you acknowledge your acceptance of this Agreement and will continue in effect until terminated by you or Company as set forth in this Section. Company may terminate 
    this Agreement at any time without notice, including if Company ceases to support the Services, which Company may do in its sole discretion, or upon the Customers failure to pay the Fees. In addition, 
    this Agreement will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this Agreement.
    Upon termination of this Agreement or the Customer’s subscription, the following post-termination process shall apply:
    -	Data Export Period: The Customer will have a period of thirty (30) days from the effective termination date to export their data from the Services. The Company will provide reasonable assistance 
    during this period to facilitate data export.

    -	Data Deletion: Following the expiration of the data export period, all Customer data will be permanently deleted from the Company's systems unless otherwise required by applicable law. 
    The Company is not responsible for retaining any data after this period.

    -	Final Payment Requirements: The Customer remains obligated to pay any outstanding Fees, including amounts incurred up to and including the termination date, in accordance with the terms of this Agreement.

    -	Access Termination: Upon termination, the Customer's access and rights to the Services will be revoked, and all associated accounts, credentials, and access rights will be deactivated.
    The Customer acknowledges and agrees that it is their responsibility to export their data within the defined period and that the Company shall not be liable for any loss or unavailability of data
     after the data export period. Termination of this Agreement will not limit any of Company's rights or remedies at law or in equity.
6.	Changes to Terms
    We reserve the right to modify or amend this Agreement without notice at any time. The latest Agreement will be accessible through the Software and posted on the Site, and you should always review 
    this Agreement prior to using the Services to ensure that you have a current understanding of this Agreement under which you are permitted to access the Services. If we make material modifications or 
    amendments to this Agreement, we will notify you by reasonable means which could include notification through the Services or by direct communication to you via email or your account. Use by you of the 
    Services following any modifications or amendments to this Agreement shall signify your acceptance of such modifications or amendments.
7.	Collection and Use of Your Information
    You acknowledge that when you use the Services, Company may use automatic means (including, for example, cookies and web beacons) to collect information about your device and about your use of the Services. 
    You also may be required to provide certain information about yourself as a condition to using the Services or certain of its features or functionality. All information we collect through or in connection 
    with the Services is subject to our Privacy Policy. By using, and providing information to the Company through the Services, you consent to all actions taken by us with respect to your information in 
    compliance with the Privacy Policy.
    Except for (i) personally identifiable information such as names, addresses, and financial information and (ii) information you submit in relation to the Software, by submitting unsolicited information 
    and content through the Services, you grant Company and each of its respective licensees, successors, and assigns a non-exclusive, worldwide, perpetual, fully-paid and royalty free license (with the right to 
    sublicense) to store, use, copy, reproduce, modify, publicly perform, display, distribute, adapt, and create derivate works of, and to promote, market, and exploit such submission. By sending any unsolicited 
    submission to us, you waive the right to make any claim against Company, its parents or affiliates related to use of the submission, including any claim of “moral rights” or attribution.
    By uploading or submitting any content you represent and warrant that you own or have the necessary rights or licenses to submit such content. You also represent and warrant that any uploaded material does not:
    •	infringe any copyright or other intellectual property or other rights of any other person or entity;
    •	contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable; promote sexually explicit or pornographic material, violence, 
      or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;
    •	violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that 
      otherwise may be in conflict with this Agreement or our Privacy Policy;
    •	promote any illegal activity, or advocate, promote, or assist any unlawful act;
    •	involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter, or advertising;
    •	give the impression that the content emanates from or is endorsed by Company or any other person or entity, if this is not the case.
    We, in our sole discretion, reserve the right to monitor, remove, or edit any user content for any or no reason at any time. We may disclose your identity or other information about you to any third party who 
    claims that user content submitted by you violates their legal rights, including intellectual property or privacy rights. We may take appropriate legal action, including referral to law enforcement authorities, 
    for any illegal or unauthorized use of the Services. We have the right to cooperate with any law enforcement authorities or court orders requesting or directing us to disclose the identity or any other information 
    of anyone posting materials on or through the Services. YOU WAIVE AND HOLD HARMLESS COMPANY FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY COMPANY IN RESPONSE TO AN INVESTIGATION BY LAW ENFORCEMENT AUTHORITIES 
    OR AS A CONSEQUENCE OF COMPANY’S ACTIVITIES INTENDED TO COMPLY WITH LAW OR REGULATIONS.
8.	System Infrastructure
    The Services are hosted on a single MongoDB database with logical data isolation to ensure the security and separation of Customer data. Hosting is provided exclusively in the AWS US region, leveraging reliable and 
    secure cloud infrastructure. The standard backup tier includes data retention for 7, 30, and 90 days, depending on the backup configuration.
    Data location options are not available as part of the standard Services. However, Customers may request alternative data hosting locations through a reseller of the Services, subject to feasibility and the payment of
    additional costs as determined by the Company.
9.	Compliance & Monitoring
    The Services incorporate robust activity tracking measures to ensure transparency and security:
    -	System Access Logging: All access to the system is logged and retained for a period of thirty (30) days to monitor and audit user activity.
    -	Permission Change Records: Modifications to user permissions are recorded to maintain an accurate history of access changes.
    -	Security Logs: Security-related activities, such as login attempts and system alerts, are logged and retained for ninety (90) days to facilitate security investigations and compliance needs.
    To maintain the integrity of the Services, the Company enforces strict compliance with this Agreement:
    -	Account Suspension: The Company reserves the right to immediately suspend Customer accounts in the event of a violation of these Terms, including unauthorized use, security breaches, or exceeding system quotas.
    -	Termination Triggers: Clear termination criteria include repeated violations, failure to resolve account issues, or activity deemed harmful to the system or other users.
    -	Appeal Process: Customers may request a review of enforcement actions within five (5) business days of notice. Appeals are subject to review by the Company and will be resolved within a reasonable timeframe.
    -	Quota Enforcement: The Company strictly enforces subscription quotas, including processing volume and storage limits. Customers exceeding their allocated quotas may be subject to suspension, additional charges, or 
      other corrective measures as determined by the Company.
    These measures are in place to ensure a secure, fair, and efficient environment for all users of the Services.
10.	Third-Party Materials 
    The Services may display, include, or make available third-party content (including data, information, software, and other products, services, and/or materials) or provide links to third-party websites or services, 
    including through third-party advertising ("Third-Party Materials"). You acknowledge and agree that Company is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness, validity, 
    copyright compliance, legality, decency, quality, or any other aspect thereof. Company does not assume and will not have any liability or responsibility to you or any other person or entity for any Third-Party Materials. 
    Third-Party Materials and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties' terms and conditions.
11.	Disclaimer of Warranties 
    THE SOFTWARE IS PROVIDED TO CUSTOMER "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES 
    AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SOFTWARE, INCLUDING ALL IMPLIED WARRANTIES OF 
    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR TRADE PRACTICE. WITHOUT LIMITATION TO THE FOREGOING, 
    COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE SOFTWARE WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK WITH ANY OTHER SERVICES, SOFTWARES, 
    SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS, OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION 
    OF OR LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU. 
12.	Limitation of Liability
    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS, RESELLERS, OR SERVICE PROVIDERS, HAVE ANY LIABILITY ARISING FROM OR RELATED TO YOUR 
    USE OF OR INABILITY TO USE THE SOFTWARE OR THE SERVICES AND SERVICES FOR:
    (a)	PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER CONSEQUENTIAL, INCIDENTAL, 
        INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES.
    (b)	DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE SOFTWARE. 
    THE FOREGOING LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR COMPANY WAS ADVISED OF THE 
    POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. 
13.	Regulatory Compliance Disclaimer
    The Services are provided as a general-purpose tool and do not guarantee compliance with any specific regulatory requirements, laws, or guidelines applicable to the Customer or its industry. It is solely the Customer's 
    responsibility to ensure that its use of the Services complies with all applicable laws, regulations, and industry standards, including but not limited to data privacy, security, and recordkeeping requirements. 
    THE COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS, RESELLERS, AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL LIABILITY FOR ANY CLAIMS, PENALTIES, FINES, OR DAMAGES ARISING 
    OUT OF THE CUSTOMER'S FAILURE TO COMPLY WITH REGULATORY OR LEGAL OBLIGATIONS. BY USING THE SERVICES, THE CUSTOMER ACKNOWLEDGES THAT IT IS RESPONSIBLE FOR IMPLEMENTING ANY ADDITIONAL PROCESSES, POLICIES, OR MEASURES NECESSARY 
    TO MEET ITS REGULATORY COMPLIANCE REQUIREMENTS.
14.	Service Delivery & Support Structure
    The Company will use commercially reasonable efforts to deliver the Services in a reliable and efficient manner. However, the Company does not guarantee any specific uptime or availability levels for the Services. Additionally, 
    the Company reserves the right to schedule maintenance for the Services as needed to perform updates, upgrades, or other necessary activities to maintain the Services. The Customer will be provided advance notice of scheduled 
    maintenance whenever possible.
    The performance and availability of the Services are dependent on third-party infrastructure, including but not limited to AWS and MongoDB. The Company disclaims any liability for interruptions, delays, or outages caused by 
    issues within these third-party systems or services.
    Support for the Software is provided through a tiered framework to ensure efficient issue resolution:
    1.	Level 1 and Level 2 Support: The Reseller is responsible for handling basic support inquiries and addressing common technical issues. Customers should direct all initial support requests to the Reseller.
    2.	Level 3 Support & Escalation: Uncommon or complex technical issues that cannot be resolved at Level 1 or Level 2 will be escalated to the Company. The Reseller will serve as the single point of contact for such 
        escalations, using predefined criteria agreed upon by the Reseller and Company to determine when escalation is necessary. A clear and structured escalation process is in place to ensure that issues requiring 
        Level 3 support are promptly communicated and addressed. The Reseller will coordinate the escalation process and facilitate communication between the Customer and Company.
    3.	Level 4 Support & Formal Dispute: If the issue remains unresolved after escalation, either party may initiate a formal dispute by providing written notice to the other party. Upon receipt of such notice, the parties 
        agree to enter a mandatory discussion period of thirty (30) days to attempt resolution through good-faith negotiations. Formal disputes should only be initiated as a last resort.
    Both the Reseller and Company utilize shared support tools to streamline issue tracking, escalation, and resolution, ensuring transparency and efficiency in support delivery.
15.	Mobile Networks
    If you access the Services through a mobile network, or signup to receive text messages, your mobile network provider’s messaging, data, and other rates and fees may apply. We shall have no responsibility or 
    liability for any data fees you incur through your use of the Services. Some features of the Services may be restricted by or may be incompatible with your mobile network or device.
16.	Release; Indemnification
    To the fullest extent permitted by law, you hereby forever release, waive and discharge Company, and its respective affiliated companies, parents, subsidiaries, Resellers, and franchisees, t
    ogether with officers, directors, shareholders, employees, agents, and independent contractors of all such entities (collectively, the “Released Parties”)   from any and all claims, demands, injuries, 
    damages, actions or causes of action related to your use of the Services, any content you submit, post to, or transmit through the Services, or your breach or alleged breach of this Agreement (collectively, “Claims”) 
    against any member of the Release Parties, or anyone acting on their behalf.  Further, you hereby agree to defend, indemnify and hold harmless each member of the Released Parties from and against all liabilities, claims, 
    damages and expenses (including reasonable attorney’s fees and costs) arising from or related to any such Claims. If you live in a jurisdiction that does not allow for the waiver of liability, the above waiver of liability 
    does not apply to you.
17.	Miscellaneous
    a.	Severability. If any provision of this Agreement is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all 
        other provisions of this Agreement will continue in full force and effect.
    b.	Governing Law. This Agreement is governed by and construed in accordance with the internal laws of the State of Florida without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or p
        roceeding arising out of or related to this Agreement or the Services shall be instituted exclusively in the federal courts of the United States or the courts of the State of Florida in each case located in Tampa and 
        Hillsborough County. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts. 
    c.	Force Majeure. Neither party shall be liable for any failure or delay in performance under this Agreement (except for payment obligations) due to causes beyond its reasonable control, including but not limited to acts of God, 
        natural disasters, government actions, labor disputes, pandemics, acts of terrorism, war, failure of third-party service providers or suppliers, interruptions or failures of utility or telecommunications services, or other 
        events or circumstances that are unforeseeable and beyond the reasonable control of the affected party (“Force Majeure Event”).The affected party shall provide prompt written notice to the other party of the Force Majeure Event, 
        including an estimate of its expected duration and the anticipated impact on performance. The obligations of the affected party shall be suspended for the duration of the Force Majeure Event, and the parties shall use 
        reasonable efforts to minimize the impact of the Force Majeure Event on the performance of this Agreement. If the Force Majeure Event continues for a period exceeding thirty (30) days, either party may terminate this Agreement 
        upon written notice to the other without further obligation or liability, except for any obligations that accrued prior to the Force Majeure Event.
    d.	Geographic Restrictions. The Software and Services are based in the United States and provided for access and use only by persons located in the United States. You acknowledge that you may not be able to access all or some of 
        the Software and Services outside of the United States and that access thereto may not be legal by certain persons or in certain countries. If you access the Software and Services from outside the United States, you are 
        responsible for compliance with local laws.
    e.	Arbitration. The Parties agree that any controversy, claim, or dispute arising out of or relating to this Agreement, or the breach thereof, or arising out of or relating to the Agreement, or the termination thereof, including 
        any claims under federal, state, or local law, shall be resolved by binding arbitration administered by a single arbitrator of the American Arbitration Association (“AAA”). The seat of any such arbitration will be Hillsborough 
        County, Florida, or a location determined by the mutual written consent of both Parties.  The Parties agree that any award rendered by the arbitrator shall be final and binding, and that judgment upon the award may be entered 
        in any court having jurisdiction thereof.  The Parties agree that any such arbitration shall be conducted pursuant to the then current Streamlined Commercial Arbitration Rules, and judgment upon the award may be entered in any 
        court having jurisdiction thereof.  The arbitration will be based on the submission of documents and there shall be no in-person or oral hearing.  In the event of a dispute between the Parties, the prevailing Party shall be 
        entitled to reasonable attorneys’ fees and costs incurred prior to and during any litigation or arbitration proceedings including interest from the date said fees and costs were advanced by the prevailing Party at the rate 
        of ten percent (10%) per annum.
    f.	Class Action Waiver. The Parties agree that any dispute, claim, or controversy arising out of or relating to this Agreement, the Services, or the relationship between the parties, shall be resolved on an individual basis 
        and not as part of any class, consolidated, or representative action. The Customer expressly waives any right to participate in any class action, class arbitration, or any other form of representative proceeding. If a dispute 
        is subject to arbitration or court action, it will be resolved solely on behalf of the individual party seeking resolution and not as part of any class action. This waiver shall apply to the fullest extent permitted by applicable 
        law, and if any court or arbitrator finds this waiver unenforceable, the dispute will proceed on an individual basis without any class or representative action.
    g.	Entire Agreement. This Agreement and our Privacy Policy constitute the entire agreement between you and Company with respect to the Services and supersede all prior or contemporaneous understandings and agreements, whether 
        written or oral, with respect to the Services. 
    h.	Waiver. No failure to exercise, and no delay in exercising, on the part of either party, any right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or power 
        hereunder preclude further exercise of that or any other right hereunder. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern. 

            `}
          </pre>
        </div>
        {showAcceptPrivacy && (
          <SpaceBetween size={'xs'} direction="vertical">
            <Checkbox
              onChange={({ detail }) => setPrivacyAccepted(detail.checked)}
              checked={privacyAccepted}
            >
              I have read and understand the above text.
            </Checkbox>
            <Checkbox
              onChange={({ detail }) => setCountryAccepted(detail.checked)}
              checked={countryAccepted}
            >
              I confirm that I am domiciled in the United States and agree to
              use MetaTrace in compliance with U.S. laws.
            </Checkbox>
          </SpaceBetween>
        )}
        <Modal
          visible={showRejectDialog}
          footer={
            <SpaceBetween size={'xxxs'} direction="horizontal">
              <Button onClick={() => setShowRejectDialog(false)}>Cancel</Button>
              <Button onClick={() => logout()}>Ok</Button>
            </SpaceBetween>
          }
        >
          The system will not be usabale until you have accepted the Terms and
          Conditions.
        </Modal>
      </>

      {/* </SpaceBetween> */}
    </Modal>
  );
};
