import {
  Box,
  Button,
  Cards,
  Container,
  Link,
  SpaceBetween
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
const mainPageCards = [
  {
    cardTitle: 'Compare and Contrast Documents',
    cardText:
      'Compare Multiple Documents to Identify Differences and Add New Information',
    children: 'card1'
  },
  {
    cardTitle: 'Collaborate on the Same Document',
    cardText: 'Analyze and link content across multiple documents',
    children: 'card2'
  },
  {
    cardTitle: 'Connect Information Between Documents',
    cardText: 'Link Information Across Multiple Documents',
    children: 'card3'
  },
  {
    cardTitle: 'Classify and Organize Information',
    cardText:
      'Classify and organize information. Use categories to organize information across multiple documents.',
    children: 'card4'
  },
  {
    cardTitle: 'Answer Questionnaires',
    cardText: 'Reuse Previous Responses and Knowledge Base Information',
    children: 'card5'
  },
  {
    cardTitle: 'Trace Information to Source',
    cardText: 'Trace and View Source Documents',
    children: 'card6'
  },
  {
    cardTitle: 'Flow Down Changes in Requirements',
    cardText: 'Discover Dependencies and Pass On Changes',
    children: 'card7'
  },
  {
    cardTitle: 'Create Knowledge Base of Documents',
    cardText: 'Make Documents Searchable and Add Them to a Knowledge Base',
    children: 'card8'
  },
  {
    cardTitle: 'Read and Analyze Multiple Documents',
    cardText: 'Aggregate and Filter Information Across Documents',
    children: 'card9'
  }
];
export const MainPageCards = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Cards
        cardDefinition={{
          header: (item) => item.cardTitle,

          sections: [
            {
              id: 'cardText',
              content: (item) => item.cardText
            },
            {
              id: 'link',
              content: (item) => (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    height: '50%'
                  }}
                >
                  <Button onClick={() => navigate(`/app/videos?card=card1`)}>
                    Show me how
                  </Button>
                </div>
              )
            }
          ]
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 3 }]}
        items={[
          {
            cardTitle: 'Compare and contrast documents.',
            cardText:
              'Compare multiple documents to identify differences. Add new information from secondary documents to a primary document.',
            children: 'card1'
          },
          {
            cardTitle: 'Connect Information between documents',
            cardText:
              ' Link information in one document to another. Create information connections between multiple documents.',
            children: 'card1'
          },
          {
            cardTitle: 'Answer Questionnaires',
            cardText:
              'Reuse previous responses and knowledge base information. Create an FAQ of completed questionnaires.',
            children: 'card1'
          },
          {
            cardTitle: 'Trace Information to source',
            cardText:
              'From any starting point in connected documents, trace to source documents. View and download original cited documents.',
            children: 'card1'
          },
          {
            cardTitle: 'Create knowledge base of documents',
            cardText:
              'Make documents searchable. Add frequently referenced documents to a knowledge base.',
            children: 'card1'
          },
          {
            cardTitle: 'Read and analyze multiple documents at the same time',
            cardText:
              'Create an aggregated view of multiple documents.  Filter information  by common categories.',
            children: 'card1'
          },
          {
            cardTitle: 'Collaborate on the same document',
            cardText:
              'Collaboratively review, analyze, research the original document. Use the original document as the source of truth.',
            children: 'card1'
          },
          {
            cardTitle: 'Organize information',
            cardText:
              'Classify and organize information. Use categories to organize information across multiple documents.',
            children: 'card1'
          },
          {
            cardTitle: 'Flow down changes in requirement',
            cardText:
              'Discover information dependencies in documents and pass on new information. Keep your third party suppliers up to date.',
            children: 'card1'
          }
        ]}
      ></Cards> */}
      <div
        style={{
          width: '100%',
          height: '80vh',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around'
        }}
      >
        {mainPageCards.map((ele) => {
          return (
            <div
              style={{
                position: 'relative',
                border: '1px solid black',
                borderRadius: '15px',
                width: '300px',
                height: '200px'
                // padding: "1% 2%"
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: '3%'
                }}
              >
                <h3>{ele.cardTitle}</h3>
                <p>{ele.cardText}</p>
              </div>
              <div
                style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 10,
                  left: '50%'
                }}
              >
                <Button
                  onClick={() => navigate(`/app/videos?card=${ele.children}`)}
                >
                  Show Me How
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
