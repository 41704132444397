import {
  AlignmentType,
  CarriageReturn,
  Document,
  Footer,
  Header,
  HeadingLevel,
  LevelFormat,
  Packer,
  Paragraph,
  Tab,
  TextRun,
  convertInchesToTwip
} from 'docx';
import { sentenceItem } from '../models/interfaces';
import { getRowColor } from './getRowColor';
const META_TEXT_SIZE = 8;
const MAIN_TEXT_SIZE = 10;
const HEADING_TEXT_SIZE = 10;
export const createFile = async (
  documentName: string,
  sentences: sentenceItem[],
  reporTitle: string
) => {
  // Documents contain sections, you can have multiple sections per document, go here to learn more about sections
  // This simple example will only contain one section

  const documentChildren = [];

  const documentHeading = new Paragraph({
    text: documentName,
    heading: HeadingLevel.HEADING_1,
    thematicBreak: true
  });
  const sentenceDisplay = (sentence: sentenceItem) => {
    const line = new Paragraph({
      numbering: {
        reference: 'levelOne',
        level: 0,
        instance: 1
      },
      children: [
        new TextRun({
          size: `${MAIN_TEXT_SIZE}pt`,
          text: sentence.text,
          color: getRowColor(sentence)
        })
      ]
    });
    documentChildren.push(line);
  };

  const sentenceWithMetaData = (sentence: sentenceItem) => {
    const parametersChild = [];
    const notesChild = [];
    const kbChild = [];
    // const sentenceText = [];
    if (sentence.All_Parameters.length > 0) {
      const allP = sentence.All_Parameters.join(', ');
      const params = new TextRun({
        size: `${META_TEXT_SIZE}pt`,
        children: [
          new CarriageReturn(),
          // new Tab(),
          'Parameters: ',
          new Tab(),
          `${allP}`
        ]
      });
      parametersChild.push(params);
    }
    if (sentence.notes.length > 0) {
      for (const note of sentence.notes) {
        const params = new TextRun({
          size: `${META_TEXT_SIZE}pt`,
          children: [
            new CarriageReturn(),
            // new Tab(),
            'Notes: ',
            new Tab(),
            `${note.text}`,
            new CarriageReturn(),
            '',
            new Tab(),
            ` - ${note.owner} : ${new Date(note.date).toISOString().split('T')[0]}`
          ]
        });
        notesChild.push(params);
      }
    }

    // this will add all the kb sentences selected
    // add all connected info regardlass of type.
    // const hasConnectedInfo =
    //   (sentence.mappedParentSentences &&
    //     sentence.mappedParentSentences?.length > 0) ||
    //   (sentence.mappedTargetSentences &&
    //     sentence.mappedTargetSentences.length > 0) ||
    //   sentence.kbSentences.length > 0;
    const allConnectedInfo = [
      ...sentence.kbSentences,
      ...sentence.mappedParentSentences,
      ...sentence.mappedTargetSentences
    ];

    if (allConnectedInfo.length > 0) {
      const kbHeading = new TextRun({
        color: '#000000',
        font: 'arial',
        size: `${META_TEXT_SIZE}pt`,
        italics: true,
        underline: {
          color: '#000000',
          type: 'single'
        },
        children: [
          new CarriageReturn(),
          // new Tab(),
          'Connected information referenced by:'
        ]
      });
      kbChild.push(kbHeading);

      // add all the sentences connected here
      for (let i = 0; i < allConnectedInfo.length; i++) {
        const link = new TextRun({
          color: '#0000FF',
          font: 'arial',
          size: `${META_TEXT_SIZE}pt`,
          italics: true,
          children: [
            new Tab(),
            `${allConnectedInfo[i].Document.documentFileName}`
          ]
        });

        const tempKB = new TextRun({
          color: '#ff0000',
          font: 'arial',
          size: `${META_TEXT_SIZE}pt`,
          italics: true,
          children: [
            new CarriageReturn(),
            // // // new Tab(),
            // `${i + 1}. `,
            // new Tab(),
            `${allConnectedInfo[i].text}`,
            new CarriageReturn()
            // `Reference: `,
            // new Tab(),
            // `${sentence.kbSentences[i].docName}`
          ]
        });
        // console.log('temp KB', tempKB);
        kbChild.push(tempKB);
        kbChild.push(link);
      }
    }
    // if (hasConnectedInfo) {
    //   const kbHeading = new TextRun({
    //     color: '#000000',
    //     font: 'arial',
    //     size: `${META_TEXT_SIZE}pt`,
    //     italics: true,
    //     underline: {
    //       color: '#000000',
    //       type: 'single'
    //     },
    //     children: [
    //       new CarriageReturn(),
    //       // new Tab(),
    //       'Connected Information '
    //     ]
    //   });
    //   kbChild.push(kbHeading);
    //   //if kb sentencs add them

    //   for (let i = 0; i < sentence.kbSentences.length; i++) {
    //     const link = new TextRun({
    //       color: '#0000FF',
    //       font: 'arial',
    //       size: `${META_TEXT_SIZE}pt`,
    //       italics: true,
    //       children: [
    //         new Tab(),
    //         `${sentence.kbSentences[i].Document.documentFileName}`
    //       ]
    //     });

    //     const tempKB = new TextRun({
    //       color: '#000000',
    //       font: 'arial',
    //       size: `${META_TEXT_SIZE}pt`,
    //       italics: true,
    //       children: [
    //         new CarriageReturn(),
    //         // // new Tab(),
    //         `${i + 1}. `,
    //         // new Tab(),
    //         `${sentence.kbSentences[i].text}`,
    //         new CarriageReturn()
    //         // `Reference: `,
    //         // new Tab(),
    //         // `${sentence.kbSentences[i].docName}`
    //       ]
    //     });
    //     // console.log('temp KB', tempKB);
    //     kbChild.push(tempKB);
    //     kbChild.push(link);
    //   }
    //   if (
    //     sentence.mappedParentSentences &&
    //     sentence.mappedParentSentences.length > 0
    //   ) {
    //     for (let i = 0; i < sentence.mappedParentSentences?.length; i++) {
    //       const link = new TextRun({
    //         color: '#0000FF',
    //         font: 'arial',
    //         size: `${META_TEXT_SIZE}pt`,
    //         italics: true,
    //         children: [
    //           new Tab(),
    //           `${sentence.mappedParentSentences[i].Document.documentFileName}`
    //         ]
    //       });

    //       const tempKB = new TextRun({
    //         color: '#000000',
    //         font: 'arial',
    //         size: `${META_TEXT_SIZE}pt`,
    //         italics: true,
    //         children: [
    //           new CarriageReturn(),
    //           // // new Tab(),
    //           `${i + 1}. `,
    //           // new Tab(),
    //           `${sentence.mappedParentSentences[i].text}`,
    //           new CarriageReturn()
    //           // `Reference: `,
    //           // new Tab(),
    //           // `${sentence.kbSentences[i].docName}`
    //         ]
    //       });
    //       // console.log('temp KB', tempKB);
    //       kbChild.push(tempKB);
    //       kbChild.push(link);
    //     }
    //   }
    //   if (
    //     sentence.mappedTargetSentences &&
    //     sentence.mappedTargetSentences.length > 0
    //   ) {
    //     for (let i = 0; i < sentence.mappedTargetSentences?.length; i++) {
    //       const link = new TextRun({
    //         color: '#0000FF',
    //         font: 'arial',
    //         size: `${META_TEXT_SIZE}pt`,
    //         italics: true,
    //         children: [
    //           new Tab(),
    //           `${sentence.mappedTargetSentences[i].Document.documentFileName}`
    //         ]
    //       });

    //       const tempKB = new TextRun({
    //         color: '#000000',
    //         font: 'arial',
    //         size: `${META_TEXT_SIZE}pt`,
    //         italics: true,
    //         children: [
    //           new CarriageReturn(),
    //           // // new Tab(),
    //           `${i + 1}. `,
    //           // new Tab(),
    //           `${sentence.mappedTargetSentences[i].text}`,
    //           new CarriageReturn()
    //           // `Reference: `,
    //           // new Tab(),
    //           // `${sentence.kbSentences[i].docName}`
    //         ]
    //       });
    //       // console.log('temp KB', tempKB);
    //       kbChild.push(tempKB);
    //       kbChild.push(link);
    //     }
    //   }
    // }

    const childrenArray = [
      // ...sentenceText,
      ...parametersChild,
      ...notesChild,
      ...kbChild
    ];
    const line = new Paragraph({
      spacing: {
        // after: 150
        before: 0,
        line: 220
      },
      // numbering: {
      //   reference: 'levelOne',
      //   level: 0,
      //   instance: 1
      // },
      indent: {
        left: convertInchesToTwip(0.1),
        hanging: convertInchesToTwip(0.5)
        // firstLine: convertInchesToTwip(0.5)
      },
      // keepLines: true,
      // keepNext: true,
      children: childrenArray
    });
    const sentenceText = new Paragraph({
      spacing: {
        after: 0,
        before: 150
      },
      numbering: {
        reference: 'levelOne',
        level: 0,
        instance: 1
      },
      keepLines: true,
      keepNext: true,
      children: [
        new TextRun({
          size: `${MAIN_TEXT_SIZE}pt`,
          text: sentence.text,
          strike: sentence.redlined ? true : false,

          color: sentence.redlined ? '#FF0000' : getRowColor(sentence)
        })
      ]
    });
    // sentenceText.push(
    //   new TextRun({
    //     size: `${MAIN_TEXT_SIZE}pt`,
    //     text: sentence.text,
    //     strike: sentence.redlined ? true : false,
    //     color: getRowColor(sentence)
    //   })
    // );
    documentChildren.push(sentenceText);
    documentChildren.push(line);
  };

  documentChildren.push(documentHeading);
  sentences.forEach((ele) => {
    if (
      ele.burden ||
      ele.kbSentences.length > 0 ||
      ele.notes.length > 0 ||
      ele.redlined ||
      (ele.mappedParentSentences && ele.mappedParentSentences?.length > 0) ||
      (ele.mappedTargetSentences && ele.mappedTargetSentences.length > 0)
    ) {
      sentenceWithMetaData(ele);
    } else {
      sentenceDisplay(ele);
    }
  });
  // uncomment from here down
  const docuHeading = new Paragraph({
    children: [
      new TextRun({
        size: `${HEADING_TEXT_SIZE}pt`,
        children: [
          `Annotated Version: ${reporTitle === '' ? 'Full Document' : reporTitle}`,
          new Tab(),
          new Tab(),
          new Tab(),
          new Tab(),
          `Created On: ${new Date(Date.now()).toISOString().split('T')[0]}`
        ]
      })
    ]
  });
  const doc = new Document({
    numbering: {
      config: [
        {
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1',
              alignment: AlignmentType.LEFT,
              style: {
                run: {
                  size: `${MAIN_TEXT_SIZE}pt`
                },
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.0),
                    hanging: convertInchesToTwip(0.5),
                    firstLine: convertInchesToTwip(0.5)
                  }
                }
              }
            }
          ],
          reference: 'levelOne'
        }
      ]
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: [docuHeading]
          })
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph('Confidential'),
              new Paragraph(`Generated By ${process.env.REACT_APP_TITLE} `)
            ]
          })
        },
        properties: {},
        children: documentChildren
      }
    ]
  });
  // uncomment from here up

  // Used to export the file into a .docx file
  Packer.toBlob(doc).then((blob) => {
    // console.log(blob);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${documentName} - ${reporTitle}.docx`;
    // Simulate a click to trigger the download
    link.click();
  });
};
