import {
  Box,
  Button,
  Cards,
  ExpandableSection,
  Header,
  Multiselect,
  Select,
  SpaceBetween
} from '@cloudscape-design/components';
import { ProjectContext } from '../../../context/projectContext';
import { useContext, useEffect, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { DocumentContext } from '../../../context/documentContext';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import { kbSentenceItem, sentenceItem } from '../../../models/interfaces';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import ShowMoreText from 'react-show-more-text';
import '../style/showMore.css';

export const ParentDocumentMapper = () => {
  const { auth } = useContext(UserContext);
  const { selectedItems } = useContext(DocumentContext);

  const [mappedResults, setMappedResults] = useState<kbSentenceItem[]>([]);

  useEffect(() => {
    setMappedResults([]);
    if (
      selectedItems &&
      selectedItems.length > 0 &&
      selectedItems[0]?.mappedParentSentences
    ) {
      if (selectedItems[0]?.mappedParentSentences.length > 0) {
        setMappedResults([...selectedItems[0].mappedParentSentences]);
      }
    }
  }, [selectedItems]);

  return (
    // <ExpandableSection
    //   variant="container"
    //   headerText="Information Referenced by Other Documents"
    //   // defaultExpanded
    //   headerCounter={`${mappedResults.length}`}
    //   headerDescription="The selected sentence is being referenced by other documents."
    // >
    <SpaceBetween size={'xs'} direction="vertical">
      {selectedItems.length > 0 && (
        <p
          style={{
            fontStyle: 'italic',
            fontSize: '1.3em',
            backgroundColor: 'yellow'
          }}
        >
          {selectedItems[0].text}
        </p>
      )}
      <div style={{ height: '60vh', overflow: 'scroll' }}>
        <Cards
          cardDefinition={{
            header: (item) => (
              <p style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                {item.text}
              </p>
            ),
            sections: [
              {
                id: 'link',
                // header: 'Link',
                content: (item) => (
                  // <Link href="https://www.example.com" external>
                  //   {item.Document.documentFileName }
                  // </Link>
                  <Button
                    onClick={() =>
                      getKBDocLinkAndOpen(
                        item.Document._id,
                        item.PageNumber,
                        auth
                      )
                    }
                    href=""
                    variant="link"
                    // loading={preLoading}
                  >
                    {item.Document.documentFileName}
                  </Button>
                )
              },
              {
                id: 'Context',
                header: 'Context',
                content: (item) => (
                  <>
                    <ShowMoreText lines={3} anchorClass="showMoreClass">
                      {item?.Context?.text ? item.Context.text : ''}
                    </ShowMoreText>
                  </>
                )
              },

              {
                id: 'score',
                header: 'Similarity',
                content: (item) =>
                  !isNaN(item.score * 100)
                    ? `${(item.score * 100).toFixed(2)} %`
                    : ''
              }
            ]
          }}
          cardsPerRow={[{ cards: 1 }]}
          items={mappedResults}
          // items={selectedItemsKbResults}
          visibleSections={['text', 'link', 'Context', 'score']}
          empty={
            <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No resources found</b>
              </SpaceBetween>
            </Box>
          }
        />
      </div>
    </SpaceBetween>
    // </ExpandableSection>
  );
};
