import {
  Button,
  Container,
  Header,
  SpaceBetween
} from '@cloudscape-design/components';
import { ButtonWrapper } from '../workWithDocument/buttonWrapper';
import { useFetcher, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../context/authContext';
import { AggregatedDocumentItem } from '../../../models/interfaces';
import { DocumentContext } from '../../../context/documentContext';
import axios from 'axios';
import { isCurrentFiltered } from '../../../helpers/getRowColor';
import { createFile } from '../../../helpers/createDocx';

export const ReportsView = () => {
  const { auth, token } = useContext(UserContext);
  const {
    getAggregatedViewSentences,
    aggSentencesLoading,
    sentences,
    setSentences,
    selectedItems,
    setSelectedItems,
    docViewFilter,
    setDocViewFilter,
    burdenAttFilter,
    setBurdenAttFilter,
    controlFilter,
    setControlFilter,
    setControlsNavigation,
    setAggSentencesLoading,
    getAllDocs,
    setFilterButtonNav,
    primaryDocument,
    setPrimaryDocument,
    allDocuments,
    reportNameFromButton
  } = useContext(DocumentContext);
  const [queryParameters] = useSearchParams();
  const [localReducedSentences, setLocalReducedSentences] =
    useState<AggregatedDocumentItem>({});
  const [documentIds, setDOcumentIds] = useState<string[]>([]);
  const [downloadButtonName, setDownloadButtonName] = useState<string>('');

  const getAndSetAllInfo = async () => {
    if (!token) return;
    const documentsToGet = queryParameters.get('documentId')?.split(',');
    console.log('documents to get', documentsToGet);
    if (documentsToGet && documentsToGet.length > 0) {
      setDOcumentIds(documentsToGet);
      console.log('+++++++++++++++++++++++++++');
      getAggregatedViewSentences(documentsToGet);
      getBurdensByDoc();
    }
  };

  const getBurdensByDoc = async () => {
    try {
      const documentIds = queryParameters.get('documentId')?.split(',');

      if (documentIds && documentIds.length > 0) {
        const burdensResult = await axios.post(
          `${process.env.REACT_APP_KORU_API}/document?type=documentInfo`,
          {
            documentIds: documentIds
          },
          auth
        );
        console.log('burdes by doc.', burdensResult);
        setControlsNavigation(burdensResult.data.result.controls);
        setFilterButtonNav(burdensResult.data.result.buttons);
      }
    } catch (e) {
      const error = e as Error;
      console.log('get buttons error', error.message);
    }
  };
  useEffect(() => {
    getAndSetAllInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAndSetAllInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // change the filering based on button selected

  useEffect(() => {
    let accumulator = {} as AggregatedDocumentItem;
    const currentPrimaryDoc = allDocuments.filter(
      (ele) => ele._id === primaryDocument?.documentId
    );
    console.log('current primary doc', currentPrimaryDoc);
    if (currentPrimaryDoc.length > 0 && primaryDocument) {
      accumulator[currentPrimaryDoc[0]._id] = {
        document: currentPrimaryDoc[0],
        sentences: []
      };
    }

    const regex = /^[0-9]+$/;
    if (
      docViewFilter === null &&
      controlFilter === '' &&
      burdenAttFilter.length === 0
    ) {
      setDownloadButtonName('Download Document');
      const filteredReduced = sentences.reduce((acc, curr) => {
        if (/^[0-9]+$/.test(curr.text)) {
          return acc;
        }
        curr.checked = false;
        if (!acc[curr.documentId_string]) {
          acc[curr.documentId_string] = {
            sentences: [curr],
            document: curr.documentId
          };
        } else {
          acc[curr.documentId_string].sentences.push(curr);
        }
        return acc;
      }, accumulator);
      setLocalReducedSentences(filteredReduced);
    } else {
      setDownloadButtonName('Download Selected Report');
      const filteredReduced = sentences.reduce((acc, curr) => {
        if (
          isCurrentFiltered(curr, docViewFilter, burdenAttFilter, controlFilter)
        ) {
          curr.checked = false;
          if (!acc[curr.documentId_string]) {
            acc[curr.documentId_string] = {
              sentences: [curr],
              document: curr.documentId
            };
          } else {
            acc[curr.documentId_string].sentences.push(curr);
          }
        }
        return acc;
      }, accumulator);
      setLocalReducedSentences(filteredReduced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentences, controlFilter, docViewFilter, burdenAttFilter]);

  const handleDownloadFile = () => {
    createFile(
      localReducedSentences[documentIds[0]].document.documentFileName,
      localReducedSentences[documentIds[0]].sentences,
      reportNameFromButton
    );
  };

  return (
    <Container
      header={
        <Header
          actions={
            <SpaceBetween size={'xs'} direction="horizontal">
              <Button
                onClick={() => {
                  setDocViewFilter(null);
                  setControlFilter('');
                  setBurdenAttFilter([]);
                }}
              >
                Clear Filters
              </Button>
              <Button onClick={() => handleDownloadFile()}>
                {downloadButtonName}
              </Button>
            </SpaceBetween>
          }
        >
          <h1>Reports</h1>
        </Header>
      }
    >
      <SpaceBetween size={'xs'} direction="vertical">
        <ButtonWrapper />
      </SpaceBetween>
    </Container>
  );
};
