import {
  Wizard,
  Container,
  SpaceBetween,
  FormField,
  Input,
  Button,
  KeyValuePairs,
  Link,
  Header,
  Textarea,
  Select,
  Slider
} from '@cloudscape-design/components';
import axios from 'axios';

import { useContext, useEffect, useState } from 'react';
import { countryOptions, usStatesOptions } from './constants';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { UserContext } from '../../../context/authContext';

type Address = {
  street1: string;
  street2: string;
  city: string;
  zip: string;
};
enum AddressNames {
  street1 = 'street1',
  street2 = 'street2',
  city = 'city',
  zip = 'zip'
}

export const SignupPage = () => {
  const { auth} = useContext(UserContext);
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [address, setAddress] = useState<Address>({
    street1: '',
    street2: '',
    city: '',
    zip: ''
  });
  const [usState, setUsState] = useState<OptionDefinition>(
    {} as OptionDefinition
  );
  const [country, setCountry] = useState<OptionDefinition>(
    {} as OptionDefinition
  );
  const [companyName, setCompanyName] = useState<string>('');
  const [primaryContact, setPrimaryContact] = useState<string>('');
  const [tel, setTel] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [primaryEmail, setPrimaryEmail] = useState<string>('');
  const [accountManager, setAccountManager] = useState<string>('');
  const [createAccountLoading, setCreateAccountLoading] =
    useState<boolean>(false);
  const [users, setUsers] = useState<number>(0);
  const [storage, setStorage] = useState<number>(0);
  const [pages, setPages] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(175);
  const [approxPages, setApproxPages] = useState<number>(0);
  const [backUpEmail, setBackupEmail] = useState<string>('');

  // functions
  const handleChange = (detail: string, name: AddressNames) => {
    console.log('hanlde change', detail, name);
    const temp = address;
    temp[name] = detail;
    console.log('combo', temp[name]);
    // const str = JSON.stringify(temp);
    // const parse = JSON.parse(str);
    setAddress({ ...temp });
  };
  const createAccount = async () => {
    try {
      setCreateAccountLoading(true);
     
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/account?type=createNewCustomer`,
        {
          account: {
            companyName: companyName,
            address: address,
            contactNumber: tel,
            primaryAdmin: primaryContact,
            primaryEmail: primaryEmail,
            accountManager: accountManager
          }
        }
      );

      setCreateAccountLoading(false);
    } catch (e) {
      const error = e as Error;
      setCreateAccountLoading(false);
    }
  };
  const handleReset = () => {
    setAddress({
      street1: '',
      street2: '',
      city: '',
      zip: ''
    });
    setUsState({} as OptionDefinition);
    setAccountManager('');
    setCompanyName('');
    setTel('');
    setPrimaryContact('');
    setPrimaryEmail('');
    setNotes('');
  };

  const calculatePrice = () => {
    const tempPrice = 175;
    const userPrice = users * 35;
    const storagePrice = storage * 15;
    const pagesPrice = (pages / 500) * 15;
    const sum = tempPrice + userPrice + storagePrice + pagesPrice;
    setFinalPrice(sum);
  };

  // useEffects
  useEffect(() => {
    calculatePrice();
  }, [users, storage, pages]);

  useEffect(() => {
    setApproxPages((storage / 5) * 13000);
  }, [storage]);

  const wizardSteps = [
    {
      title: 'Company Details',
      content: (
        <>
          <Container
            header={
              <Header
                actions={
                  <SpaceBetween size={'xs'} direction="horizontal">
                    <Button onClick={() => handleReset()}>Cancel</Button>
                    <Button
                      onClick={() => createAccount()}
                      loading={createAccountLoading}
                    >
                      Create Account
                    </Button>
                  </SpaceBetween>
                }
              >
                Create New Customer Account
              </Header>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '100%',
                paddingTop: '5%'
              }}
            >
              <div
                style={{
                  height: '100%',
                  width: '45%'
                }}
              >
                <SpaceBetween size="l">
                  <FormField label="Street">
                    <Input
                      name="street1"
                      value={address.street1}
                      onChange={({ detail }) =>
                        handleChange(detail.value, AddressNames.street1)
                      }
                      placeholder="123 Main St"
                    />
                  </FormField>

                  <FormField label="Street">
                    <Input
                      name="street1"
                      value={address.street2}
                      onChange={({ detail }) =>
                        handleChange(detail.value, AddressNames.street2)
                      }
                      placeholder="Suite A"
                    />
                  </FormField>

                  <FormField label="City">
                    <Input
                      name="city"
                      value={address.city}
                      onChange={({ detail }) =>
                        handleChange(detail.value, AddressNames.city)
                      }
                      placeholder="City"
                    />
                  </FormField>

                  <FormField label="State">
                    <Select
                      selectedOption={usState}
                      options={usStatesOptions}
                      onChange={({ detail }) =>
                        setUsState(detail.selectedOption)
                      }
                    ></Select>
                  </FormField>

                  <FormField label="ZIP Code">
                    <Input
                      name="zip"
                      value={address.zip}
                      onChange={({ detail }) =>
                        handleChange(detail.value, AddressNames.zip)
                      }
                      placeholder="ZIP Code"
                    />
                  </FormField>
                  <FormField label="Country">
                    <Select
                      selectedOption={country}
                      options={countryOptions}
                      onChange={({ detail }) =>
                        setCountry(detail.selectedOption)
                      }
                    ></Select>
                  </FormField>
                </SpaceBetween>
              </div>
              <div
                style={{
                  height: '100%',
                  width: '45%'
                }}
              >
                <SpaceBetween size="l">
                  <FormField label="Company Name">
                    <Input
                      value={companyName}
                      onChange={({ detail }) => setCompanyName(detail.value)}
                      placeholder="ACME Comapny"
                    />
                  </FormField>
                  <FormField label="Primary Contact">
                    <Input
                      value={primaryContact}
                      onChange={({ detail }) => setPrimaryContact(detail.value)}
                      placeholder="Joe Blogs"
                    />
                  </FormField>
                  <FormField label="Tel No">
                    <Input
                      value={tel}
                      onChange={({ detail }) => setTel(detail.value)}
                      placeholder="(206) 867 5309"
                    />
                  </FormField>
                  <FormField label="Primary Email">
                    <Input
                      value={primaryEmail}
                      onChange={({ detail }) => setPrimaryEmail(detail.value)}
                      placeholder="jBlogs@acmecompany.com"
                    />
                  </FormField>
                </SpaceBetween>
              </div>
            </div>
          </Container>
        </>
      )
    },
    {
      title: 'Select Options',
      content: (
        <Container
          footer={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <h2>{`Recurring Monthly Charges = $ ${finalPrice}`}</h2>
            </div>
          }
        >
          <SpaceBetween size={'s'} direction="vertical"></SpaceBetween>

          <div>
            <p>
              Our base package includes 5GB of Storage (Document and Metadata)
              as well as 1000 Pages of System analysis per month and One User.
            </p>
          </div>
          <FormField label="Additional GigaBytes of Storage">
            {approxPages > 0 && (
              <p
                style={{ margin: ' 1% 1%' }}
              >{`approximately ${approxPages} pages`}</p>
            )}
            <Slider
              min={0}
              max={100}
              step={5}
              value={storage}
              onChange={({ detail }) => setStorage(detail.value)}
            />
          </FormField>
          <FormField label="Additional Pages / month">
            <Slider
              min={0}
              max={10000}
              step={500}
              value={pages}
              onChange={({ detail }) => setPages(detail.value)}
            />
          </FormField>
          <FormField label="Additional Users">
            <Slider
              min={0}
              max={10}
              step={1}
              value={users}
              onChange={({ detail }) => setUsers(detail.value)}
            />
          </FormField>
        </Container>
      )
    },
    {
      title: 'Recovery Email',
      content: (
        <Container>
          <SpaceBetween direction="vertical" size="l">
            Please provide an additional email address that can be used if
            primary admin cannot access the system. This will allow you to
            request access with all correspondence going to the backup email
            address.
            <FormField label="Recovery Email Address">
              <Input
                value={backUpEmail}
                onChange={({ detail }) => setBackupEmail(detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )
    },
    {
      title: 'Review and Signup',
      content: (
        <SpaceBetween size="xs">
          <Container>
            <KeyValuePairs
              columns={2}
              items={[
                {
                  label: 'Company Name',
                  value: companyName
                },
                {
                  label: 'Additional Storage',
                  value: storage
                },
                {
                  label: 'Address Line 1',
                  value: address.street1
                },
                {
                  label: 'Pages Processing',
                  value: pages
                },
                {
                  label: 'Address Line 2',
                  value: address.street2
                },
                {
                  label: 'Additional Users',
                  value: users
                },
                {
                  label: 'City',
                  value: address.city
                },
                {
                  label: 'Recovery Email',
                  value: backUpEmail
                },

                {
                  label: 'State',
                  value: usState.label
                },
                {
                  label: 'Backup Email Address',
                  value: backUpEmail
                },
                {
                  label: 'Country',
                  value: country.label
                },
                {
                  label: 'Monthly Cost',
                  value: `$${finalPrice}`
                }
              ]}
            />
          </Container>
        </SpaceBetween>
      )
    }
  ];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center'
        // outline: '1px solid green'
      }}
    >
      <div
        style={{
          width: '80%',
          // border: '1px solid red',
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <h1>{`${process.env.REACT_APP_TITLE} Signup`}</h1>
        <Wizard
          i18nStrings={{
            // stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
              `Step ${stepNumber} of ${stepsCount}`,
            skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
            navigationAriaLabel: 'Steps',
            cancelButton: 'Cancel',
            previousButton: 'Previous',
            nextButton: 'Next',
            submitButton: 'Signup',
            optional: 'optional'
          }}
          onNavigate={({ detail }) =>
            setActiveStepIndex(detail.requestedStepIndex)
          }
          // onCancel={() => }
          activeStepIndex={activeStepIndex}
          allowSkipTo
          steps={wizardSteps}
        />
      </div>
    </div>
  );
};
