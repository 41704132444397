export interface videoItems {
  link: string;
  items: string[];
  heading: string;
}

interface videoPage {
  pageHeading: string;
  videos: videoItems[];
}

export interface IVideos {
  [key: string]: videoPage;
}
export const videosObject: IVideos = {
  // card1: {
  //   pageHeading: 'Compare and Contrast Documents',
  //   videos: [
  //     {
  //       link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/1.mp4',
  //       heading:
  //         'Inside your project, select one or more folders and multiple documents to view in aggregate.',
  //       items: []
  //     },
  //     {
  //       link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video2.mp4',
  //       heading: 'Identify Gaps Using Categories in Explore Document',
  //       items: []
  //     },
  //     {
  //       link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video3.mp4',
  //       heading:
  //         'Compare similar content between documents by category from Explore Document',
  //       items: []
  //     },
  //     {
  //       link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video4.mp4',
  //       heading: 'Contrast content by:\n\n',
  //       items: [
  //         'Redlining text that is different',
  //         'Add a note to indicate a difference in content'
  //       ]
  //     },
  //     {
  //       link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video5.mp4',
  //       heading:
  //         'Compare similar text across documents and Link Sentences To Documents',
  //       items: [
  //         'Select text to compare',
  //         'Use Link Sentences to Documents to compare content similar content in other documents within your project',
  //         'Create a connection between sentences and Save Mapped'
  //       ]
  //     },
  //     {
  //       link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video6+.mp4',
  //       heading:
  //         'Identify gaps between a primary document and secondary document(s)',
  //       items: ['Use the categories in Explore Document to identify gaps']
  //     },
  //     {
  //       link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/video1.mp4',
  //       heading:
  //         'Link Sentences to Documents and Add Info to the primary document',
  //       items: []
  //     }
  //   ]
  // }
  card1: {
    pageHeading: 'Compare and Contrast Documents',
    // subHeading:'Compare Multiple Documents to Identify Differences and Add New Information',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/1.mp4',
        heading: 'View Documents in Aggregate',
        items: [
          'Inside your project, select one or more folders and multiple documents to view in aggregate.',
          'View the documents in aggregate'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/2.mp4',
        heading: 'Identify Gaps Using Categories in Explore Document',
        items: [
          'Use the categories in Explore Document to identify gaps between documents.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/3.mp4',
        heading: 'Contrast Content by Redlining Text and Adding Notes',
        items: [
          'Redline text that is different.',
          'Add a note to indicate a difference in content.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/4.mp4',
        heading: 'Compare and Link Text Across Documents',
        items: [
          'Select text to compare.',
          'use Link Sentences to Documents to find similar content.',
          'Create Connections.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/5.mp4',
        heading: 'Identify Gaps Between Primary and Secondary Documents',
        items: [
          'Use categories in Explore Document to identify gaps between a primary document and secondary documents.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/6.mp4',
        heading: 'Link Sentences to Documents and Add Info to Primary Document',
        items: [
          'Link sentences from secondary documents',
          'add missing information to the primary document.'
        ]
      }
    ]
  },
  card2: {
    pageHeading: 'Collaborate on the Same Document',
    // subHeading: 'Analyze and link content across multiple documents',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/2a.mp4',
        heading: 'Redline a Document',
        items: ['Use redlining to mark changes directly on the document.']
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/3a.mp4',
        heading: 'Star a Document to Highlight Important Information',
        items: ['Highlight key information by starring it for easy reference.']
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/4a.mp4',
        heading: 'Review a Document and See Starred Information',
        items: ['Quickly review and access information that has been starred.']
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/5a.mp4',
        heading: 'Review Notes and Add Comments or Changes',
        items: [
          'Collaboratively add comments or propose changes to the document.'
        ]
      }
    ]
  },
  card3: {
    pageHeading: 'Connect Information Between Documents',
    // subHeading: 'Link Information Across Multiple Documents',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/9.mp4',
        heading: 'Find and Link Similar Content Across Documents',
        items: [
          'Inside your project, select a folder and a document to view.',
          'Select a sentence or category from Explore Document.',
          'Use Link Sentences to Documents to find similar content in other documents within your project.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/9.mp4',
        heading: 'Create and Save Mapped Connections',
        items: [
          'Create a connection between sentences and Save Mapped to establish links between documents.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/9.mp4',
        heading: 'Use the Knowledge Base to Create Connections"',
        items: [
          'Use the Knowledge Base to search content in other documents. Save Pinned to establish connections between multiple documents.'
        ]
      }
    ]
  },
  card4: {
    pageHeading: 'Classify and Organize Information',
    // subHeading:
    //   'Classify and organize information. Use categories to organize information across multiple documents.',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/7.mp4',
        heading: 'Classify and Organize Information',
        items: [
          'Inside your project, select a document and click View Document.',
          'Review unclassified obligations',
          'Assign / Review highlighted obligations',
          'Assign obligations to a category using Analyze Sentences.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/14.mp4',
        heading:
          'Exercises - Review Unclassified Obligations and Reclassify if applicable.',
        items: [
          'Review Unclassified Obligations',
          'Reclassify unclassified obligations'
        ]
      }
    ]
  },
  card5: {
    pageHeading: 'Answer Questionnaires',
    // subHeading: 'Reuse Previous Responses and Knowledge Base Information',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/12.mp4',
        heading: 'Upload and Publish Answers in AnswerBank (DOC)',
        items: [
          'Upload completed questionnaires in DOC format and publish answers to the AnswerBank.'
        ]
      },
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/13.mp4',
        heading: 'Upload and Publish Answers in AnswerBank (CSV)',
        items: [
          'Upload completed questionnaires in CSV format and publish answers to the AnswerBank.'
        ]
      }
    ]
  },
  card6: {
    pageHeading: 'Trace Information to Source',
    // subHeading: 'Trace and View Source Documents',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/11.mp4',
        heading: 'Trace Connections to Source Documents',
        items: [
          'From a connected document, trace back to the original source document.'
        ]
      }
    ]
  },
  card7: {
    pageHeading: 'Flow Down Changes in Requirements',
    // subHeading: 'Discover Dependencies and Pass On Changes',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/10.mp4',
        heading: 'Identify Gaps and Update Dependencies',
        items: [
          'Identify gaps between primary and secondary documents and update dependencies.'
        ]
      }
    ]
  },
  card8: {
    pageHeading: 'Create Knowledge Base of Documents',
    // subHeading: 'Make Documents Searchable and Add Them to a Knowledge Base',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/11.mp4',
        heading: 'Make Documents Searchable',
        items: [
          'Add documents to a knowledge base and make them searchable by category.'
        ]
      }
    ]
  },
  card9: {
    pageHeading: 'Read and Analyze Multiple Documents',
    // subHeading: 'Aggregate and Filter Information Across Documents',
    videos: [
      {
        link: 'https://674674125182-us-west-2-rogerdev-video-files.s3.us-west-2.amazonaws.com/1a.mp4',
        heading: 'Aggregate and Analyze Documents',
        items: [
          'Create an aggregated view of multiple documents and filter by common categories.'
        ]
      }
    ]
  }
};
