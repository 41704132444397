import { Grid, SpaceBetween, Button } from '@cloudscape-design/components';
import { decideButton } from '../../../helpers/decideButtonDisabled';
import { filterEnum, Controls } from '../../../models/interfaces';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DocumentContext } from '../../../context/documentContext';
import './documentControlButtons.css';
import { BurdenAttributionView } from './burdenAttributionView';
import PopoverWrapper from '../popoverWrapper';

export const DocumentControlButtons = (props: { domain: string }) => {
  const { domain } = props;
  const {
    docViewFilter,
    setDocViewFilter,
    controlFilter,
    setControlFilter,
    controlsNavigation,
    controls,
    setSelectedItems,
    filterButtonNav,
    setReportNameFromButton
  } = useContext(DocumentContext);
  const [buttnsArray, setButtonArray] = useState<any[]>([]);
  const [showBurdenAttribution, setShowBurdenAttribution] =
    useState<boolean>(false);

  const handleButtonText = (buttonText: string) => {
    setReportNameFromButton(buttonText);
  };

  const constantButtons = [
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.service);
              setControlFilter('');
              setSelectedItems([]);
              handleButtonText('SERVICE LEVEL REQUIREMENTS');
            }}
            variant={
              docViewFilter === filterEnum.service ? 'primary' : 'normal'
            }
            disabled={!filterButtonNav['Sevice']}
          >
            SERVICE LEVEL REQUIREMENTS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.terminiation);
              setControlFilter('Termination');
              setSelectedItems([]);
              handleButtonText('Termination');
            }}
            variant={
              docViewFilter === filterEnum.terminiation ? 'primary' : 'normal'
            }
            disabled={!filterButtonNav['Termination']}
          >
            TERMINATIONS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.project_timelines);
              setControlFilter('');
              setSelectedItems([]);
              handleButtonText('PROJECT TIMELINES');
            }}
            variant={
              docViewFilter === filterEnum.project_timelines
                ? 'primary'
                : 'normal'
            }
            disabled={!filterButtonNav['Project Timelines']}
          >
            PROJECT TIMELINES
          </Button>
        </div>
      )
    },
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.Financial_Information);
              setControlFilter('');
              setSelectedItems([]);
              handleButtonText('FINANCIAL INFORMATION');
            }}
            variant={
              docViewFilter === filterEnum.Financial_Information
                ? 'primary'
                : 'normal'
            }
            disabled={!filterButtonNav['Financial Information']}
          >
            FINANCIAL INFORMATION
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.location);
              setControlFilter('');
              setSelectedItems([]);
              handleButtonText('PLACES & LOCATIONS');
            }}
            variant={
              docViewFilter === filterEnum.location ? 'primary' : 'normal'
            }
          >
            PLACES & LOCATIONS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.burden_attribution);
              setShowBurdenAttribution(true);
              setControlFilter('');
              setSelectedItems([]);
              handleButtonText('BURDEN ASSIGNMENT');
            }}
            variant={
              docViewFilter === filterEnum.burden_attribution
                ? 'primary'
                : 'normal'
            }
          >
            BURDEN ASSIGNMENT
          </Button>
        </div>
      )
    },
    {
      domainCode: '(UNC)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.unclassified_burden);
              setControlFilter('');
              setSelectedItems([]);
              handleButtonText('UNCLASSIFIED TEXT');
            }}
            variant={
              docViewFilter === filterEnum.unclassified_burden
                ? 'primary'
                : 'normal'
            }
          >
            UNCLASSIFIED TEXT
          </Button>
        </div>
      )
    },
    {
      domainCode: '(UNC)',
      button: (
        <div className="button">
          <PopoverWrapper
            popoverContent={
              'Link sentences form secondary document to primary documents'
            }
            sideToShow={'LEFT'}
            aboveBelow={'TOP'}
          >
            <Button
              onClick={() => {
                setDocViewFilter(filterEnum.netNew);
                setControlFilter('');
                setSelectedItems([]);
                handleButtonText('ADDITIONAL INFORMATION');
              }}
              variant={
                docViewFilter === filterEnum.netNew ? 'primary' : 'normal'
              }
              disabled={!filterButtonNav['New Information']}
            >
              ADDITIONAL INFORMATION
            </Button>
          </PopoverWrapper>
        </div>
      )
    }
  ];
  const controlsToIgnore = [
    'Financial Details',
    'Termination',
    'Project Timeline'
  ];
  useEffect(() => {
    const toInclude = Object.keys(controlsNavigation);
    const tempButtonArray = [];
    const thisDomainControls = controls.filter(
      (ele) => ele.domainCode === domain && toInclude.includes(ele.controlName)
    );
    for (const control of thisDomainControls) {
      if (controlsToIgnore.includes(control.controlName)) continue;
      tempButtonArray.push(
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.control);
              setControlFilter(control.controlName);
              setSelectedItems([]);
              handleButtonText(`${control.controlButton}`);
            }}
            // disabled={decideButton(cont, controlsNavigation)}
            variant={
              controlFilter === control.controlName ? 'primary' : 'normal'
            }
          >
            {control.controlButton}
          </Button>
        </div>
      );
    }
    const constButtons = constantButtons.filter(
      (ele) => ele.domainCode === domain
    );
    for (const conButton of constButtons) {
      tempButtonArray.push(conButton.button);
    }
    setButtonArray(tempButtonArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    controlsNavigation,
    controlFilter,
    controls,
    docViewFilter,
    filterButtonNav
  ]);

  return (
    <div className="buttonContainer">
      <>
        {!showBurdenAttribution ? <>{buttnsArray}</> : null}
        {showBurdenAttribution ? <BurdenAttributionView /> : null}
      </>
    </div>
  );
};
