import {
  Alert,
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  SpaceBetween
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import { UserRow } from './ManageUserRow';
import { PasswordChecker, PasswordMatch } from '../passwordChecker';

export const ManageUsers = () => {
  // const [users, setUsers] = useState<IUser[]>([]);
  const [alert, setAlert] = useState<{
    showAlert: boolean;
    message: string;
    type: 'error' | 'success';
  }>({
    showAlert: false,
    message: '',
    type: 'success'
  });
  const [subToUpdate, setSubToUpdate] = useState<string>('');
  const [updatePasswordModalVisible, setUpdatePasswordModalVisible] =
    useState<boolean>(false);
  const [first, setFirst] = useState<string>('');
  const [second, setSecond] = useState<string>('');
  const [passwordUpdateLoading, setPasswordUpdateLoadiing] =
    useState<boolean>(false);
  const { auth, users, setUsers, getUsers } = useContext(UserContext);

  const addUser = () => {
    const tempUser = {
      sub: '',
      username: '',
      name: '',
      password: null,
      customerId: '',
      isAdmin: false,
      email: '',
      groups: [],
      reseller: false
    };
    const tempUserArray = users;
    tempUserArray.push(tempUser);
    setUsers([...tempUserArray]);
  };
  const saveUserSignup = async (
    email: string,
    name: string,
    password: string,
    isAdmin: boolean
  ) => {
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/user?type=addNew`,
        { email, name, password, isAdmin: `${isAdmin}` },
        auth
      );
      if (results.data.response.$metadata.httpStatusCode === 200) {
        setAlert({ showAlert: true, message: 'User created', type: 'success' });
      } else {
        setAlert({ showAlert: true, message: 'User created', type: 'success' });
        console.log('error', results);
      }
      getUsers();
    } catch (e) {
      const error = e as Error;
      setAlert({ showAlert: true, message: error.message, type: 'error' });
    }
  };

  const updateUser = async (
    thissub: string,
    name: string,
    email: string,
    isAdmin: boolean
  ) => {
    try {
      const attributesArray = [
        { Name: 'custom:isAdmin', Value: `${isAdmin ? 'true' : 'false'}` },
        { Name: 'name', Value: name },
        { Name: 'email', Value: email }
      ];
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/user?type=update`,
        { username: thissub, UserAttributes: attributesArray },
        auth
      );
      getUsers();
      setAlert({ showAlert: true, message: 'User updated', type: 'success' });
    } catch (e) {
      console.log('error from update', e);
      setAlert({
        showAlert: true,
        message: 'Error updating User',
        type: 'error'
      });
    }
  };
  const updatePassword = async (sub: string, newPassword: string) => {
    try {
      setPasswordUpdateLoadiing(true);
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/user?type=updatePassword`,
        { username: sub, newPassword },
        auth
      );
      setAlert({ showAlert: true, message: 'Password Reset', type: 'success' });
      setPasswordUpdateLoadiing(false);
      setUpdatePasswordModalVisible(false);
      getUsers();
    } catch (e) {
      const error = e as Error;

      setAlert({
        showAlert: true,
        message: `Error resetting password - ${error.message}`,
        type: 'error'
      });
      setPasswordUpdateLoadiing(false);
      setUpdatePasswordModalVisible(false);
    }
  };
  return (
    <Container
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size={'s'}>
              {/* <Button>Save</Button> */}
              <Button onClick={() => addUser()}>Add User</Button>
            </SpaceBetween>
          }
        >
          Manage Users
        </Header>
      }
    >
      {alert.showAlert ? (
        <Alert
          type={alert.type}
          dismissible
          onDismiss={() =>
            setAlert({ showAlert: false, message: '', type: 'success' })
          }
        >
          {alert.message}
        </Alert>
      ) : null}
      <SpaceBetween size="l">
        {users.length > 0
          ? users.map((userRow, index) => {
              return (
                <UserRow
                  key={index}
                  user={userRow}
                  // forcePasswordReset={forcePasswordReset}
                  saveUser={saveUserSignup}
                  updateUser={updateUser}
                  updatePassword={updatePassword}
                  setSubToUpdate={setSubToUpdate}
                  setUpdatePasswordModalVisible={setUpdatePasswordModalVisible}
                />
              );
            })
          : null}
      </SpaceBetween>
      <Modal
        visible={updatePasswordModalVisible}
        onDismiss={() => {
          setUpdatePasswordModalVisible(false);
          setSubToUpdate('');
        }}
        header={`Reset Password for ${users.filter((ele) => ele.sub === subToUpdate)[0]?.name}`}
        footer={
          <SpaceBetween size={'l'} direction="horizontal">
            <Button
              onClick={() => {
                setUpdatePasswordModalVisible(false);
                setSubToUpdate('');
                setFirst('');
                setSecond('');
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!subToUpdate || first !== second}
              onClick={() => updatePassword(subToUpdate, first)}
              loading={passwordUpdateLoading}
            >
              Reset
            </Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween size={'l'} direction="vertical">
          <FormField label="New Password">
            <Input
              autoComplete={false}
              type="text"
              ariaLabel="New Password"
              value={first}
              onChange={(event) => setFirst(event.detail.value)}
            />
            {first.length > 0 ? <PasswordChecker value={first} /> : null}
          </FormField>
          <FormField label="Retype New Password">
            <Input
              autoComplete={false}
              type="text"
              value={second}
              onChange={(event) => setSecond(event.detail.value)}
            />
            {second.length > 0 ? (
              <PasswordMatch first={first} second={second} />
            ) : null}
          </FormField>
        </SpaceBetween>
      </Modal>
    </Container>
  );
};
