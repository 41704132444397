import { useEffect, useState } from 'react';
import { outline, sentenceItem } from '../../../models/interfaces';

export const ShowSvgArrow = (ele: sentenceItem) => {
  const upArrow =
    ele.mappedTargetSentences && ele.mappedTargetSentences.length > 0;
  const downArrow =
    ele.mappedParentSentences && ele.mappedParentSentences.length > 0;
  const both = upArrow && downArrow;
  if (both) {
    return (
      <g transform={`rotate( 180 10,7.5)`}>
        <line
          stroke="black"
          strokeWidth={2}
          x1="8"
          y1="0"
          x2="8"
          strokeLinecap="round"
          y2="15"
        ></line>
        <line
          stroke="black"
          strokeWidth={2}
          x1="12"
          y1="0"
          x2="12"
          strokeLinecap="round"
          y2="15"
        ></line>
        <polyline
          stroke="black"
          strokeLinecap="round"
          strokeWidth={2}
          fill="none"
          points="4,5 8,0"
        ></polyline>
        <polyline
          stroke="black"
          strokeLinecap="round"
          strokeWidth={2}
          fill="none"
          points="12,15 16,10"
        ></polyline>
      </g>
    );
  }
  if (upArrow) {
    return (
      <g>
        <line
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          x1="10"
          y1="0"
          x2="10"
          y2="15"
        ></line>
        <polyline
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          fill="none"
          points="5,7.5 10,0 15,7.5"
        ></polyline>
      </g>
    );
  }
  if (downArrow) {
    return (
      <g transform={`rotate( 180 10,7.5)`}>
        <line
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          x1="10"
          y1="0"
          x2="10"
          y2="15"
        ></line>
        <polyline
          stroke="black"
          strokeWidth={2}
          strokeLinecap="round"
          fill="none"
          points="5,7.5 10,0 15,7.5"
        ></polyline>
      </g>
    );
  }
  return null;
};

export const ShowLink = (sentence: sentenceItem) => {
  if (
    (sentence.mappedTargetSentences &&
      sentence.mappedTargetSentences.length > 0) ||
    (sentence.mappedParentSentences &&
      sentence.mappedParentSentences.length > 0)
  ) {
    return (
      <g transform={`scale(0.7)`}>
        <g id="SVGRepo_bgCarrier" stroke-width="0" />

        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <g id="SVGRepo_iconCarrier">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.975 14.51a1.05 1.05 0 0 0 0-1.485 2.95 2.95 0 0 1 0-4.172l3.536-3.535a2.95 2.95 0 1 1 4.172 4.172l-1.093 1.092a1.05 1.05 0 0 0 1.485 1.485l1.093-1.092a5.05 5.05 0 0 0-7.142-7.142L9.49 7.368a5.05 5.05 0 0 0 0 7.142c.41.41 1.075.41 1.485 0zm2.05-5.02a1.05 1.05 0 0 0 0 1.485 2.95 2.95 0 0 1 0 4.172l-3.5 3.5a2.95 2.95 0 1 1-4.171-4.172l1.025-1.025a1.05 1.05 0 0 0-1.485-1.485L3.87 12.99a5.05 5.05 0 0 0 7.142 7.142l3.5-3.5a5.05 5.05 0 0 0 0-7.142 1.05 1.05 0 0 0-1.485 0z"
            fill="#000000"
          />
        </g>
      </g>
    );
  } else {
    return null;
  }
};
